<template>
  <div>
    <Whatsappbtn />
    <!-- header -->
    <!-- <div class="h-2 bg-smile-maroon"></div> -->
    <MainHeader />
    <!-- Main Banner -->
    <MainBanner />

    <!-- Our Services -->
    <div class="py-10 bg-smile-gray">
      <div class="container">
        <SubtitleHeading
          heading="Our Services"
          custom="text-smile-maroon"
          bar="bg-smile-maroon"
        />
        <p
          class="pt-4 text-sm text-center lg:text-base lg:w-3/4 lg:mx-auto text-smile-maroon"
        >
          Our team provides one-stop dental services for adults and children.
          Check out our range of dental services for you and your loved ones.
        </p>

        <!-- Service Content -->
        <div class="pt-6 xl:pt-10">
          <div class="flex flex-wrap lg:justify-center">
            <div
              v-for="(item, i) in services"
              :key="i"
              class="w-1/2 px-1 pb-3 md:w-1/4"
            >
              <div
                class="mx-auto bg-white rounded-full shadow-md lg:h-56 lg:w-56"
              >
                <img
                  :src="item.image"
                  :alt="item.alt"
                  class="p-2 mx-auto md:w-auto md:h-auto"
                />
              </div>
              <div class="py-4">
                <h1
                  v-html="item.title"
                  class="text-lg font-semibold text-center text-smile-maroon"
                >
                  {{ item.title }}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Our Gallery -->
    <div class="py-10">
      <div class="container">
        <SubtitleHeading
          heading="Our Gallery"
          custom="text-smile-maroon"
          bar="bg-smile-maroon"
        />
        <carousel
          :autoplay="true"
          :loop="true"
          :responsive="{
            0: { items: 2, nav: false },
            700: { items: 3, nav: false },
            1000: { items: 3, nav: false },
          }"
        >
          <!-- <div class="flex flex-wrap pt-6 md:justify-center"> -->
          <CoolLightBox :items="items" :index="index" @close="index = null">
          </CoolLightBox>
          <div
            v-for="(image, imageIndex) in items"
            :key="imageIndex"
            @click="index = imageIndex"
            class="px-1 py-1 mt-5"
          >
            <img :src="image" class="w-full" />
          </div>
          <!-- </div> -->
        </carousel>
      </div>
    </div>

    <!-- Why Choose Us -->
    <div
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/choose-us.jpg')"
    >
      <div class="container">
        <SubtitleHeading
          heading="Why Choose Us"
          custom="text-white"
          bar="bg-white"
        />

        <!-- content choose us -->
        <div class="pt-10 md:flex md:flex-wrap lg:w-3/4 lg:mx-auto">
          <div class="py-4 md:w-1/4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              class="w-24 h-24 mx-auto text-white fill-current"
            >
              <path
                d="M64.756 83.253h-6.259l-3.571-6.531v-10.38h-9.852v10.38l-3.572 6.531h-6.259a2.458 2.458 0 000 4.915h29.513a2.459 2.459 0 000-4.915z"
              />
              <path
                d="M89.395 64.781L67.892 51.758a5.674 5.674 0 00-.678-.344V22.425a10.27 10.27 0 00-1.813-5.857c-2.995-4.347-8.979-5.648-13.62-2.96l-2.867 1.659a7.23 7.23 0 00-9.475.651l-3.298 3.297a1.899 1.899 0 000 2.683l9.251 9.25a1.896 1.896 0 002.682 0l3.298-3.298a7.23 7.23 0 001.03-8.923l1.841-1.065c2.438-1.41 5.561-.753 7.11 1.496.618.896.945 1.958.945 3.068v28.516H39.851c-1.963 0-3.777-1-4.758-2.623l-16.78-27.762c-1.518-2.512-4.884-3.371-7.513-1.917l-.004.003c-2.622 1.449-3.519 4.652-2.005 7.158l19.948 33.003c.981 1.623 2.795 2.623 4.759 2.623h28.254c1.042 0 2.063.283 2.944.816l18.812 11.395c2.561 1.551 5.952.828 7.579-1.613 1.628-2.448.871-5.691-1.692-7.244zm-27.083-13.84c.001-.014.002-.016.005 0h-.005z"
              />
            </svg>
            <div class="pt-4">
              <h1
                class="text-xl font-semibold leading-tight text-white md:text-lg"
              >
                Wide Range of <br> Dental Service
              </h1>
            </div>
          </div>

          <div class="py-4 md:w-1/4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              class="w-24 h-24 mx-auto text-white fill-current"
            >
              <path
                d="M90.527 49.816a10.18 10.18 0 00-20.349.516V67.3a3.394 3.394 0 00-3.394-3.395H32.851a3.394 3.394 0 00-3.393 3.395V50.333c0-5.623-4.559-10.18-10.182-10.18-5.622.001-10.18 4.559-10.179 10.181 0 4.315 2.72 8.16 6.787 9.598v17.55a3.393 3.393 0 003.394 3.393h3.393v6.787h6.788v-6.787H70.18v6.787h6.786v-6.787h3.395a3.392 3.392 0 003.393-3.393v-17.55a10.185 10.185 0 006.773-10.116z"
              />
              <path
                d="M32.851 50.333v10.181h33.935V50.333a13.598 13.598 0 0110.18-13.144v-7.217c-.01-9.367-7.6-16.957-16.967-16.967h-20.36c-9.366.01-16.957 7.601-16.968 16.967v7.217a13.598 13.598 0 0110.18 13.144zm23.753-23.754a3.394 3.394 0 110 6.787 3.394 3.394 0 010-6.787zm0 10.179a3.395 3.395 0 11-.001 6.79 3.395 3.395 0 01.001-6.79zM43.031 26.579a3.393 3.393 0 11.002 6.786 3.393 3.393 0 01-.002-6.786zm0 10.179a3.394 3.394 0 110 6.788 3.394 3.394 0 010-6.788z"
              />
            </svg>
            <div class="pt-4">
              <h1
                class="text-xl font-semibold leading-tight text-white md:text-lg"
              >
                Comfortable <br />
                Environment
              </h1>
            </div>
          </div>

          <div class="py-4 md:w-1/4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              class="w-24 h-24 mx-auto text-white fill-current"
            >
              <path
                d="M79.415 59.127l-8.374 10.952a8.858 8.858 0 01-7.035 3.476H48.457a2.214 2.214 0 010-4.427h13.477c2.897 0 5.322-2.372 5.268-5.268a5.166 5.166 0 00-5.164-5.065H46.539a12.823 12.823 0 00-16.34-1.19l-1.667 1.19v25.092h38.503c4.52 0 8.73-2.298 11.174-6.101l8.641-13.44a4.547 4.547 0 00.722-2.458c-.002-4.347-5.516-6.212-8.157-2.761zM21.889 54.367h-7.675A2.214 2.214 0 0012 56.582v29.52c0 1.223.991 2.213 2.214 2.213h7.675c1.223 0 2.214-.99 2.214-2.213v-29.52a2.214 2.214 0 00-2.214-2.215zM54.26 24.981c-.451.346-.699.869-.785 1.322-.117.626.031 1.207.381 1.48.123.096.259.191.404.285v-3.087z"
              />
              <path
                d="M55.837 49.644c10.174 0 18.45-8.277 18.45-18.45s-8.276-18.45-18.45-18.45c-10.173 0-18.45 8.277-18.45 18.45s8.277 18.45 18.45 18.45zm-5.585-12.418a1.475 1.475 0 012.043-.427c.797.522 1.297.748 1.965.842v-6.233c-.943-.432-1.648-.848-2.227-1.302-1.225-.96-1.784-2.627-1.459-4.35.354-1.877 1.661-3.371 3.409-3.897.094-.028.186-.054.277-.077v-.919a1.476 1.476 0 012.952 0v.757c1.433.248 2.446.954 2.937 1.517a1.476 1.476 0 01-2.218 1.948c-.033-.034-.268-.259-.719-.426v4.769c.263.097.524.192.781.282 2.594.917 4.037 3.319 3.591 5.977-.347 2.066-1.919 4.161-4.372 4.783v1.057a1.476 1.476 0 01-2.952 0v-.917c-1.192-.103-2.18-.425-3.581-1.341a1.475 1.475 0 01-.427-2.043z"
              />
              <path
                d="M58.672 35.197c.097-.577.136-1.952-1.461-2.625v4.741c.83-.476 1.326-1.316 1.461-2.116z"
              />
            </svg>
            <div class="pt-4">
              <h1
                class="text-xl font-semibold leading-tight text-white md:text-lg"
              >
                Reasonable <br />
                Price
              </h1>
            </div>
          </div>

          <div class="py-4 md:w-1/4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              class="w-24 h-24 mx-auto text-white fill-current"
            >
              <path
                d="M32.569 68.83a2.483 2.483 0 000 4.966 2.48 2.48 0 002.481-2.482 2.481 2.481 0 00-2.481-2.484z"
              />
              <path
                d="M71.996 66.746c-2.926-1.054-5.271-2.136-7.168-3.315-.334-.225-.451-.251-.742-.412-1.071-.591-2.099-.104-2.393.273l-.249.309c-2.226 2.602-9.552 5.754-9.554 5.755-.572.156-.994.68-.994 1.304v.002l.024 9.001c0 5.548-4.477 10.061-9.972 10.061-5.508 0-9.989-4.513-9.989-10.061v-3.768a4.848 4.848 0 01-3.264-4.58 4.855 4.855 0 119.71 0 4.853 4.853 0 01-3.142 4.537v3.643c0 3.931 2.993 7.132 6.679 7.132 3.681 0 6.677-3.201 6.677-7.132l.019-8.775v-.002V70.659l-.004-.01c-.027-.561-.444-.977-.903-1.207-.005-.004-7.804-3.894-10.091-6.805-.295-.376-1.321-.862-2.393-.273-1.996 1.102-4.71 2.008-8.14 3.245-10.578 3.814-13.626 7.703-13.626 10.356v17.374h73.138V75.965c.003-2.653-3.046-5.403-13.623-9.219z"
              />
              <path
                d="M31.801 34.26c.543 7.15 3.388 4.068 3.975 7.201 1.153 6.164 3.51 6.399 4.337 8.521.186.477.295 2.757.295 3.515 0 2.537-.139 3.881-1.132 5.169-.226.292-.605 1.246-.12 1.973 2.196 3.273 6.436 6.026 10.419 6.078 3.985-.052 8.224-2.804 10.42-6.078.486-.725.107-1.681-.12-1.973-.992-1.288-1.131-2.632-1.131-5.169 0-.758.108-3.039.295-3.515.826-2.122 3.184-2.357 4.336-8.521.589-3.132 3.431-.05 3.976-7.201 0-2.85-1.551-3.559-1.551-3.559s.788-4.217 1.097-7.462c.383-4.045-2.361-14.492-16.995-14.492h-.65c-14.635 0-17.379 10.447-16.996 14.492.309 3.245 1.098 7.462 1.098 7.462s-1.553.709-1.553 3.559z"
              />
            </svg>
            <div class="pt-4">
              <h1
                class="text-xl font-semibold leading-tight text-white md:text-lg"
              >
                Experienced <br />
                Dentist
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Our Testimonial -->
    <div class="py-10">
      <div class="container">
        <SubtitleHeading
          heading="Testimonials"
          custom="text-smile-maroon"
          bar="bg-smile-maroon"
        />

        <!-- testimonial content -->
        <div class="pt-8">
          <carousel
            :autoplay="true"
            :loop="true"
            :responsive="{
              0: { items: 1, nav: false },
              700: { items: 2, nav: false },
              1000: { items: 3, nav: false },
            }"
          >
            <!--carousel area-->
            <div v-for="(item, i) in testimonial" :key="i" class="md:px-2">
              <div class="px-3 py-6 bg-smile-lite">
                <p
                  v-html="item.p"
                  class="flex items-center h-64 pb-4 text-base text-smile-maroon"
                >
                  {{ item.p }}
                </p>
                <h1
                  class="pt-4 text-xl font-semibold text-center text-smile-maroon"
                >
                  {{ item.client }}
                </h1>
              </div>
            </div>
          </carousel>
        </div>
      </div>
    </div>

    <!-- About Us -->
    <div class="py-10 bg-smile-maroon">
      <div class="container">
        <div class="lg:flex lg:flex-wrap lg:max-w-4xl lg:mx-auto">
          <div class="lg:w-1/2 lg:px-3">
            <div class="mb-5 bg-white rounded-lg">
              <div class="">
                <img
                  src="/images/dentist.jpg"
                  alt="Smileland Dental Clinic"
                  class="w-full rounded-t-lg"
                />
              </div>
              <div class="py-6">
                <h1
                  class="px-4 text-3xl font-bold text-center lg:text-left text-smile-brown"
                >
                  Our Dentist
                </h1>
                <!-- Content About Us -->
                <div class="px-2 pt-5 lg:px-5">
                  <div class="text-base text-center text-gray-900 lg:text-left">
                    <p class="pb-4">
                      Dr Nicholas is a general dental practitioner with special
                      interest in Aesthetic dentistry and Orthodontics
                    </p>
                    <p class="pb-4">
                      Graduated from Manipal University (MMMC) and completed his
                      compulsory government service in Kuala Lumpur. He was
                      previously attached to the Oral Maxillofacial Surgery
                      (OMFS) and Pediatric Dentistry department in Hospital
                      Kuala Lumpur.
                    </p>
                    <p>
                      Dr Nicholas completed his 2 years residency in the Master
                      Class of Beethoven’s Orthodontics by Dr. Chris Chang,
                      Orthodontist from Taiwan. As an avid learner, he often
                      attends courses and conferences locally and
                      internationally. He anticipates making cheerful, solid and
                      splendid smile for you and your family in the years ahead.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="lg:w-1/2 lg:px-3">
            <div class="mb-5 bg-white rounded-lg">
              <div class="">
                <img
                  src="/images/about-us.jpg"
                  alt="Smileland Dental Clinic"
                  class="w-full rounded-t-lg"
                />
              </div>
              <div class="py-6">
                <h1
                  class="px-4 text-3xl font-bold leading-tight text-center lg:text-left text-smile-brown"
                >
                  Our Value and Mission
                </h1>
                <!-- Content About Us -->
                <div class="px-2 pt-5 lg:px-5">
                  <div class="text-base text-center text-gray-900 lg:text-left">
                    <p class="pb-4">
                      Quality Patient Care is our top priority, where we are
                      dedicated to providing expert dental treatments for all
                      age groups. We are committed to ensuring that you and your
                      family receive the best possible dental treatment and care
                      from our friendly, gentle and reliable dentists in a
                      comfortable environment.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- faq -->
    <div class="py-10">
      <div class="container">
        <SubtitleHeading
          heading="FAQ"
          custom="text-smile-maroon"
          bar="bg-smile-maroon"
        />
        <div class="lg:w-2/3 lg:mx-auto">
          <Accordion :faq="faq" :focus="false" class="pt-4" />
        </div>
      </div>
    </div>

    <!-- enquiry -->
    <!-- <div
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/enquire.jpg')"
    >
      <div class="container" id="element">
        <div
          class="px-3 py-10 bg-opacity-75 bg-smile-maroon md:w-3/4 md:mx-auto"
        >
          <SubtitleHeading
            heading="Send an Enquiry"
            custom="text-white"
            bar="bg-white"
          />
          <p class="pt-3 text-base text-white">
            Fill out the form below and we'll get back to you as soon as
            possible
          </p>
          
          <div id="enquire" class="pt-6">
            <iframe
              allowTransparency="true"
              style="min-height: 530px; height: inherit; overflow: auto"
              width="100%"
              id="contactform123"
              name="contactform123"
              marginwidth="0"
              marginheight="0"
              frameborder="0"
              src="https://feedback.activamedia.com.sg/my-contact-form-5714129.html"
              ><p>
                Your browser does not support iframes. The contact form cannot
                be displayed. Please use another contact method (phone, fax etc)
              </p></iframe
            >
          </div>
        </div>
      </div>
    </div> -->

    <!-- Footer -->
    <MainFooter />
    <Copyright />
  </div>
</template>

<script>
import SubtitleHeading from "@/components/SubtitleHeading.vue";
import Accordion from "@/components/Accordion.vue";
import carousel from "vue-owl-carousel";
import MainHeader from "@/components/MainHeader.vue";
import MainBanner from "@/components/MainBanner.vue";
import MainFooter from "@/components/MainFooter.vue";
import Copyright from "@/components/Copyright.vue";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import Whatsappbtn from "@/components/Whatsappbtn.vue"

export default {
  components: {
    SubtitleHeading,
    Accordion,
    CoolLightBox,
    carousel,
    MainHeader,
    MainBanner,
    MainFooter,
    Copyright,
    Whatsappbtn
  },
  data() {
    return {
      services: [
        {
          image: "/images/icon/icon-1.png",
          alt: "Braces",
          title: "Braces",
        },
        {
          image: "/images/icon/icon-4.png",
          alt: "Whitening",
          title: "Whitening",
        },
        {
          image: "/images/icon/icon-7.png",
          alt: "Scaling",
          title: "Scaling",
        },
        {
          image: "/images/icon/icon-5.png",
          alt: "Filling",
          title: "Filling",
        },
        {
          image: "/images/icon/icon-9.png",
          alt: "Veneer",
          title: "Veneer",
        },
        {
          image: "/images/icon/icon-6.png",
          alt: "Root Canal",
          title: "Root Canal",
        },
        {
          image: "/images/icon/icon-2.png",
          alt: "Crown and Bridges",
          title: "Crown and Bridges",
        },
        {
          image: "/images/icon/icon-10.png",
          alt: "Extraction",
          title: "Extraction",
        },
        {
          image: "/images/icon/icon-11.png",
          alt: "Implant",
          title: "Implant",
        },
        {
          image: "/images/icon/icon-12.png",
          alt: "Surgical Extraction of Wisdom Tooth",
          title: "Surgical Extraction of Wisdom Tooth",
        },
        {
          image: "/images/icon/icon-13.png",
          alt: "Denture",
          title: "Denture",
        },
        {
          image: "/images/icon/icon-14.png",
          alt: " Kid’s Dentistry",
          title: " Kid’s Dentistry",
        },
      ],

      faq: [
        {
          q: "Will teeth whitening treatments damage my enamel?",
          a:
            "Teeth whitening treatments have gotten much safer as new technological developments in both over-the counter and in-office products have evolved.",
        },
        {
          q: "How often should dental checkup and scaling be done?",
          a: "Dental checkup and scaling is recommended once in six months",
        },
        {
          q: "What does it mean when you have bleeding gums?",
          a:
            "If your gums bleed when you brush, you may have gum disease and it could lead to bad breath, then inflamed, receding gums, and eventually even tooth loss.",
        },
        {
          q: "The cavity seems small, is it a big deal?",
          a:
            "While the situation may seem minor now, it could worsen into intense, excruciating pain in the middle of the night, leading to a costly dental emergency. Cavities reduce teeth strength and may cause teeth breakage.",
        },
        {
          q: "Is going to the dentist painful?",
          a:
            "Dental visits today are typically uneventful and pain-free, especially if you stick to a routine of dental visits.",
        },
      ],
      testimonial: [
        {
          p:
            "Great dental experience I had at Smileland! :) Worth my trip traveling from Cheras to PJ! Nice & clean dental clinic with friendly staffs. Dr Nicholas is super gentle and polite, constantly asking how I feel & make sure I am comfortable throughout the whole treatment. Good services with affordable price :D Highly recommend!!",
          client: "Wei Li Loo",
        },
        {
          p:
            "Nice and very comfortable clinic. Took my 7 months baby to see Dr Nicholas and he was very attentive to our concerns regarding our baby. What came to my surprise was Dr Nicholas slotted in an appointment for my baby before the clinics opening hours as a safety measure! Thank you very much Smileland. You really made us smile",
          client: "Wan Nur Huda",
        },
        {
          p:
            "Was looking around for a good dental clinic nearby. The Dr is great and the nurses were friendly too, this clinic makes me feel like I am at home.",
          client: "Ashley",
        },
        {
          p:
            "Awesome place and awesome people here. I was annoyed by my wisdom tooth and it always get swollen and inflammation, then I consult the dentist here, Dr Nicholas. Very awesome dentist! He share me a lot of awesome knowledge about our teeth.",
          client: "Koh Ting Keong",
        },
        {
          p:
            "Affordable & Excellent Dental Service. Attentive dentist and staff, responsive on social media. Was bothered by a mild toothache for few days, found this dentistry on Monday, made an appointment and got it fixed with fillings on Tuesday! Apparently under that mild and subtle toothache, was 2 holes, deep and almost to the nerve",
          client: "Myee Tan",
        },
        {
          p:
            "Did my filling and retainer here. Clinic is equipped with advance intraoral camera. Able to see my before and after photo after my treatment! Received my retainer even during MCO as Dr Nic arranged it to deliver to my house. Dr Nic explains the treatment patiently and gives professional advice. Nurses are well trained as well. Highly Recommend. Ps. Their clinic deco is amazing. Doesn’t feel like a dental clinic at all!!  ",
          client: "Yi Jun Thien",
        },
        {
          p:
            "Been looking for a dental clinic that is affordable and offering great services. Been to one that was very expensive (spent RM 800+ for Xray + Extraction + Scaling) in Bandar Sunway. But now, I have found one that is worth to go. A clinic with very nice interior, simple but cozy and modern look. Offers such a great hospitality with very friendly staffs and most importantly, affordable pricing! Thank you.",
          client: "Pattayo Ib",
        },
        {
          p:
            "It’s not the ordinary dental place that I used to visit. This is definitely the best experience I ever had. Cozy and clean place with good service! Thank you, Dr Nic and the team!",
          client: "Swee Chin Chun",
        },
        {
          p:
            "Brought my little girl for a tooth extraction…boy she was spoiled. Dr. Nicholas and his assistant made it very comfy for her because she was previously traumatized at a different clinic. He took the time to explain what’s the best options for her growing teeth as well. Price is reasonable too. ",
          client: "Anne Marie Cheong",
        },
        {
          p:
            "By far the best dental experience I’ve ever had. Dr Nic is very experienced and managed to treat my complex condition in which few other dentists I went to have failed. Dr and staffs are super patient, friendly and helpful. Awesome, cozy and stress-free environment that takes away all your dental fear.",
          client: "Wei Jin Yap",
        },
      ],
      items: [
        "/images/gallery-4.jpg",
        "/images/gallery-5.jpg",
        "/images/gallery-1.jpg",
        "/images/gallery-2.jpg",
        "/images/gallery-3.jpg",
        "/images/gallery-6.jpg",
        "/images/gallery-7.jpg",
        "/images/gallery-8.jpg",
        "/images/gallery-9.jpg",
        "/images/gallery-10.jpg",
        "/images/gallery-11.jpg",
        "/images/gallery-12.jpg",
        "/images/gallery-13.jpg",
        "/images/gallery-14.jpg",
        "/images/gallery-16.jpg",
        "/images/gallery-15.jpg",
      ],
      index: null,
    };
  },
};
</script>

<style>
.overlay {
  transition: 0.5s ease;
  opacity: 0;
}
.center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
.custom:hover .overlay {
  opacity: 1;
}

@media (min-width: 768px) {
  #enquire iframe {
    min-height: 480px !important;
  }
}
</style>

<template>
  <div
    class="py-10 bg-left bg-no-repeat bg-cover"
    style="background-image: url('/images/main-banner.jpg')"
  >
    <div class="container">
      <div class="py-40 md:py-48 lg:py-64 custom">
        <!-- <div class="md:w-2/3 lg:w-1/2 md:mx-auto">
          <div
            class="px-2 py-6 mx-auto bg-opacity-75 bg-smile-maroon w-6/7 md:w-full"
          >
            <h1
              class="text-5xl font-bold text-center text-white md:text-6xl xl:text-7xl"
            >
              Your Smile
            </h1>
            <h1
              class="text-2xl font-semibold text-center text-white md:text-3xl xl:text-4xl"
            >
              Is Our Upmost Priority
            </h1>
            <a
              v-if="!hideEnquiry"
              id="btn-enquire"
              href="#"
              v-scroll-to="{ el: '#element', duration: 1500 }"
            >
              <div
                class="w-1/2 py-2 mx-auto mt-5 text-base font-semibold text-center text-white border-2 border-white lg:text-xl md:text-lg md:w-1/3 hover:text-gray-800 hover:bg-white xl:w-1/4"
              >
                Enquire Now
              </div>
            </a>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
@media (min-width: 1800px){
  .custom {
    padding: 23rem 0;
  }
}
 
</style>

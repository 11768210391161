<template>
  <div class="flex items-center justify-center">
    <div class="h-xs w-6" :class="bar"></div>
    <h1 class="text-2xl lg:text-3xl px-4 font-semibold text-center" :class="custom">
      {{heading}}
    </h1>
    <div class="h-xs w-6" :class="bar"></div>
  </div>
</template>

<script>
export default {
    props:['heading','custom','bar']
};
</script>
